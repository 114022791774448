import './BookFlight.css';

import moment from 'moment';
import { compose } from 'redux';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import React, { useEffect, useState } from 'react';

import { IBE_BASE_URL } from '../../constants/config';
import AirlineLogo from '../../components/AirlineLogo';
import { setupUpgradeSupportPackage } from '../../helpers';
import FlightsFooter from '../Flights/components/FlightsFooter';
import SubscriptionForm from '../../components/SubscriptionForm';
import BookingCountdown from '../../components/BookingCountdown';
import withCallTrackingUrl from '../../Hocs/withCallTrackingUrl';
import { getPassengersFromPriceSummary } from '../../helpers/passengers';
import PriceSummary from '../PaymentConfirmation/PriceSummary/PriceSummary';
import {
  AdditionalServicesForm,
  Button,
  ContactDetailsForm,
  FlexBox,
  FormHandler,
  FormWrapper,
  Indent,
  Mobile,
  PassengerInfoForm,
  PaymentInfoForm,
  ReviewFlight,
  ReviewPolicy,
  Reviews,
  ReferralCodeBlock,
} from 'travelopod-components';

import {
  MARKETING_PRIVACY_POLICY,
  MARKETING_TERMS_AND_CONDITIONS,
  MARKETING_URL_BOOKINGRULES,
} from '../../constants/global';

const BookFlightForm = (props) => {
  const {
    buildMarketingUrl,
    countries,
    currencySource,
    flight,
    isBannersShown,
    onSubmit,
    onValidationError,
    serverErrors,
    submitting,
    travelMarket,
  } = props;

  // if origin country is US, fetch insurance premium
  const isOriginInUS = flight?.origin?.countryCode === 'US';
  const [showAdditionalService, setShowAdditionalService] = useState(isOriginInUS);

  const location = useLocation();

  const [quote, setQuote] = useState(false);
  const [loading, setLoading] = useState(false); // for fetching the insurance premium
  const [isTravelInsuranceAdded, setIsTravelInsuranceAdded] = useState(false);

  // PROMO CODE
  const [promoDiscountedAmount] = useState(null);

  const passengers = getPassengersFromPriceSummary(flight.summary);
  const upgradeSupportPackage = setupUpgradeSupportPackage(travelMarket);
  const queryFilterFromSessionStorage = sessionStorage.getItem('queryFilters');

  const footerBannerData = useSelector((state) => {
    return state.footerBanner.footerBannerData;
  });

  useEffect(() => {
    const getInsurancePremium = async (data) => {
      try {
        setLoading(true);
        const response = await fetch(`${IBE_BASE_URL}/insurance/getPremium`, {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'content-type': 'application/json',
          },
        });

        if (!response?.ok) {
          throw new Error('Get Request failed for insurance premium API');
        }

        const responseJson = await response.json();

        setLoading(false);
        setQuote({ insurancePlanCost: responseJson?.insurancePremium });
        setIsTravelInsuranceAdded(true);
      } catch (error) {
        console.log('Error while getting Insurnce Premium ', error);
        setLoading(false);
        setShowAdditionalService(false);
        setIsTravelInsuranceAdded(false);
        return;
      }
    };

    showAdditionalService &&
      getInsurancePremium(
        airlegitInsurancePremiumReqFormater({
          destination: flight.destination,
          fare: flight.price.total,
          origin: flight.origin,
          trips: flight.trips,
        }),
      );
  }, []);

  return (
    <>
      {/* MOBILE COUNTDOWN */}
      <Mobile>
        <BookingCountdown />
      </Mobile>

      {/* BOOKING FORM  */}
      <FormHandler
        onSubmit={(values) => {
          return onSubmit([
            ...values,
            {
              upgradeSupportPackage,
              quote,
            },
          ]);
        }}
        onError={onValidationError}
      >
        {/* FLIGHT DETAILS */}
        <ReviewFlight
          flight={flight}
          airlineLogoComponent={AirlineLogo}
          serviceClass={location?.state?.queryFilters?.serviceClass || queryFilterFromSessionStorage?.serviceClass}
        />

        {/* SLIDING REVIEWS */}
        <FormWrapper groupName="Reviews">
          <Reviews />
        </FormWrapper>

        {/* PASSENGER CONTACT DETAILS(EMAIL AND PHONE NUMBER) FORM */}
        <FormWrapper groupName="ContactDetails">
          <ContactDetailsForm />
        </FormWrapper>

        {/* PASSENGER INFORMATION FORM */}
        <FormWrapper groupName="PassengerInfo">
          <PassengerInfoForm
            airlineLogoComponent={AirlineLogo}
            flight={flight}
            passengers={passengers}
            serverErrors={serverErrors}
          />
        </FormWrapper>

        {/* PAYMENT INFORMATION FORM */}
        <FormWrapper groupName="PaymentInfo">
          <PaymentInfoForm countries={countries} serverErrors={serverErrors} />
        </FormWrapper>

        {/* REFERRAL INFO BLOCK */}
        <FormWrapper groupName="referralInfo">
          <ReferralCodeBlock />
        </FormWrapper>

        {/* INSURANCE BLOCK */}
        <FormWrapper withOtherFormValues groupName="AdditionalService">
          <AdditionalServicesForm
            loading={loading}
            quote={quote}
            handleTravelInsurance={(value) => {
              setIsTravelInsuranceAdded(value);
            }}
            showAdditionalService={showAdditionalService}
          />
        </FormWrapper>

        {/* REVIEW POLICY */}
        <ReviewPolicy
          links={{
            bookingRules: buildMarketingUrl(MARKETING_URL_BOOKINGRULES),
            privacyPolicy: buildMarketingUrl(MARKETING_PRIVACY_POLICY),
            termsConditions: buildMarketingUrl(MARKETING_TERMS_AND_CONDITIONS),
          }}
        />

        {/* PRICE SUMMARY */}
        <Indent ma="t-double">
          <PriceSummary
            className="book-flight-form-price-summary"
            currencySource={currencySource}
            flight={flight}
            isCancellationShown
            isTravelInsuranceAdded={isTravelInsuranceAdded}
            promoDiscountedAmount={promoDiscountedAmount}
            quote={quote}
            upgradeSupportPackage={upgradeSupportPackage}
          />
        </Indent>

        <FormWrapper groupName="subscriptionStatus">
          <SubscriptionForm />
        </FormWrapper>

        {/* SUBMIT BUTTON */}
        <FlexBox justifyContent="center" ma="t-double">
          <Button type="submit" size="large" className="book-flight-form-btn" disabled={submitting}>
            Authorize &amp; Complete Booking
          </Button>
        </FlexBox>
      </FormHandler>

      {/* CUSTOMER REVIEWS */}
      <FormWrapper groupName="Reviews">
        <Reviews />
      </FormWrapper>

      {/* FOOTER BANNER */}
      {isBannersShown && <FlightsFooter footerBannerData={footerBannerData} />}
    </>
  );
};

export default compose(withCallTrackingUrl())(BookFlightForm);

const formatSegment = (option, index, tripData) => {
  return {
    airlineName: option.airline.name,
    airlineCode: option.airline.code,
    flightno: option.flightNumber,
    originAirportCode: index !== 0 ? tripData.stops[index - 1].airportCode : tripData.origin.airportCode,
    destAirportCode: tripData?.stops?.[index]?.airportCode || tripData.destination.airportCode,
    departuretime: moment(option.departureDate, 'DDMMYY HHmm').format('YYYY-MM-DD HH:mm:ss'),
  };
};

const airlegitInsurancePremiumReqFormater = ({ trips, fare, origin, destination }) => {
  try {
    const tripLength = trips.length;
    const isRoundTrip = tripLength === 2;

    const depatureTripData = trips.find((trip) => trip.direction === 'OUTBOUND');
    const arrivalTripData = isRoundTrip ? trips.find((trip) => trip.direction === 'INBOUND') : null;

    const updatedDepatureTripData = { origin, destination, ...depatureTripData };
    const updatedArrivalTripData = { origin: { ...destination }, destination: { ...origin }, ...arrivalTripData };

    const departureSegments = updatedDepatureTripData.options.map((option, index) => {
      return formatSegment(option, index, updatedDepatureTripData);
    });

    const arrivalSegments = isRoundTrip
      ? updatedArrivalTripData.options.map((option, index) => {
          return formatSegment(option, index, updatedArrivalTripData);
        })
      : [];

    const finalData = {
      tripCost: Number(fare).toFixed(2),
      tripStartDate: moment(updatedDepatureTripData.departureDate, 'DDMMYY HHmm').format('YYYY-MM-DD HH:mm:ss'),
      tripEndDate: isRoundTrip
        ? moment(updatedArrivalTripData.arrivalDate, 'DDMMYY HHmm').format('YYYY-MM-DD HH:mm:ss')
        : moment(updatedDepatureTripData.arrivalDate, 'DDMMYY HHmm').format('YYYY-MM-DD HH:mm:ss'),
      segments: [...departureSegments, ...arrivalSegments],
    };

    return finalData;
  } catch (error) {
    console.log(
      'Error in BookFlightForm / airlegitInsurancePremiumReqFormater fn ',
      error?.message || JSON.stringify(error),
    );

    return;
  }
};
