import './index.css';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { formatPhone } from '../../../../helpers/phone';
import buzzingPhone from '../../../../assets/images/CallPhone.gif';
import { saveFooterBanner } from '../../../../store/modules/footerBannerStore';
import { getPhoneDataSelector } from '../../../../store/modules/responseTap/phoneData';

/**
 * This component is the footer banner on bottom of search page and booking page.
 * It is not shown in production for IPs non-USA.
 */

const FlightsFooter = (props) => {
  const { footerBannerConfig, footerBannerData } = props;

  let flightBannerData;

  if (footerBannerConfig) {
    flightBannerData = { ...footerBannerConfig };
  } else {
    flightBannerData = { ...footerBannerData };
  }

  const dispatch = useDispatch();
  const phoneData = useSelector((state) => getPhoneDataSelector(state));

  const handleClick = () => {
    window.location.href = formatPhone(phoneData.phone, 'RFC3966');
  };

  useEffect(() => {
    if (footerBannerConfig) {
      dispatch(saveFooterBanner(footerBannerConfig));
    }
  }, []);

  if (!flightBannerData.content) {
    return <></>;
  }

  return (
    <a onClick={handleClick} className="footer-banner-container">
      <div className="footer-banner-wrapper">
        <div className="footer-banner__content">
          <div className="footer-banner__content-wrapper">
            {/* PHONE ICON */}
            <div className="footer-banner__icon">
              <img src={buzzingPhone} alt="Buzzing-CallPhone-Icon" width={28} height={26} />
            </div>

            <div className="footer-banner__text">
              {/* CONTENT */}
              <p className="footer-banner-6 footer-banner__message">{flightBannerData.content.toUpperCase()}</p>

              {/* CALL US TEXT */}
              <p className="footer-banner__message">{flightBannerData.callUsText.toUpperCase()}</p>
            </div>

            {/* PHONE NUMBER */}
            <div className="footer-banner__phone-text">{phoneData?.phone}</div>
          </div>
        </div>
      </div>
    </a>
  );
};

export default FlightsFooter;
