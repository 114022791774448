// @flow
const {
  REACT_APP_MARKETING_BASE_URL,
  REACT_APP_OPS_API_BASE_URL,
  REACT_APP_NEW_IBE_BASE_URL,
  REACT_APP_OLD_BASE_URL,
  REACT_APP_KOUNT_DOMAIN,
  REACT_APP_KOUNT_MERCHANT_ID,
  REACT_APP_GA_ID,
  REACT_APP_DEFAULT_MODAL_DELAY_DAYS,
  REACT_APP_CALL_TRACKING_BASE_URL,
  REACT_APP_SENTRY_DSN,
  REACT_APP_FLYKART_MARKETING_BASE_URL,
  REACT_APP_API_AURIC_URL,
  REACT_APP_EXCHANGE_RATE_API,
  REACT_APP_GOOGLE_REVIEWS_MERCHANT,
  REACT_APP_ENVIRONMENT,
  REACT_APP_ADD_SHOPPERS_API,
  REACT_APP_IBE_URL,
  REACT_APP_USER_AUTH_URL,
} = process.env;

const OPS_API_URL = REACT_APP_OPS_API_BASE_URL || 'http://api-ops-flights-dev.travelopod.biz';
const CALL_TRACKING_API_URL = REACT_APP_CALL_TRACKING_BASE_URL || OPS_API_URL;

export const IBE_BASE_URL = REACT_APP_NEW_IBE_BASE_URL || 'http://flights.travelopod.com';

export const OLD_VERSION_BASE_URL = REACT_APP_OLD_BASE_URL || 'http://flights-dev.travelopod.biz';

export const MARKETING_BASE_URL = REACT_APP_MARKETING_BASE_URL || 'https://www.online.travelopod.com';
export const FLYKART_MARKETING_BASE_URL = REACT_APP_FLYKART_MARKETING_BASE_URL || 'https://www.flykart.com';
export const EXCHANGE_RATE_API = REACT_APP_EXCHANGE_RATE_API;

export const ADD_SHOPPER_API = REACT_APP_ADD_SHOPPERS_API;

export default {
  baseURL: OPS_API_URL,
  callTrackingUrl: CALL_TRACKING_API_URL,
  reserveURL: IBE_BASE_URL,
};

export const AUTH_TOKEN = 'site/newIbeFront';
export const GA_ID = REACT_APP_GA_ID;
export const KOUNT_DOMAIN = REACT_APP_KOUNT_DOMAIN || 'ssl.kaptcha.com';
export const KOUNT_MERCHANT_ID = REACT_APP_KOUNT_MERCHANT_ID || 156200;

export const DEFAULT_MODAL_DELAY_DAYS = REACT_APP_DEFAULT_MODAL_DELAY_DAYS;
export const GCR_MERCHANT_ID = REACT_APP_GOOGLE_REVIEWS_MERCHANT;
export const SENTRY_DSN = REACT_APP_SENTRY_DSN;
export const API_AURIC_URL = REACT_APP_API_AURIC_URL || 'https://vault01.auricsystems.com/vault/v2/';
export const APP_ENVIRONMENT = REACT_APP_ENVIRONMENT || 'production';

export const USER_AUTH_URL = REACT_APP_USER_AUTH_URL;
export const IBE_URL = REACT_APP_IBE_URL;
