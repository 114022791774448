// @flow
import {
  Button,
  FlexBox,
  FormHandler,
  FormWrapper,
  ReferralCodeBlock,
  ReviewPolicy,
  Text,
} from 'travelopod-components';
import { compose } from 'recompose';
import React, { useEffect, useState } from 'react';

import {
  MARKETING_PRIVACY_POLICY,
  MARKETING_TERMS_AND_CONDITIONS,
  MARKETING_URL_BOOKINGRULES,
} from '../../constants/global';
import PaymentInfoForm from './PaymentInfoForm';
import Loader from '../../components/Loader/Loader';
import PriceSummary from './PriceSummary/PriceSummary';
import AdditionalServicesForm from './AdditionalServicesForm';
import withCallTrackingUrl from '../../Hocs/withCallTrackingUrl';

const PaymentConfirmationForm = (props) => {
  const {
    billingCard,
    billingDetailCustomer,
    buildMarketingUrl,
    confirmPaymentPending,
    countries,
    isUpgradeSupportReadOnly,
    manualImport,
    onSubmit,
    onValidationError,
    paymentConfirmation,
    paymentCopyPage,
    upgradeSupportPackage,
  } = props;

  const outboundPnrTrip = paymentConfirmation.pnrTrips.find((pnrTrip) => pnrTrip.direction === 'OUTBOUND');
  const inboundPnrTrip = paymentConfirmation.pnrTrips.find((pnrTrip) => pnrTrip.direction === 'INBOUND');

  const isOriginInUS = outboundPnrTrip?.tripOriginAirport?.airportCountry?.code === 'US';
  const [showAdditionalService, setShowAdditionalService] = useState(isOriginInUS);

  const bookedInsuranceObj = paymentConfirmation?.billingDetail?.bookingInsurance || null;
  const [quote, setQuote] = useState(
    paymentCopyPage ? { insurancePlanCost: bookedInsuranceObj?.basePremium || 0 } : null,
  );

  const [loading, setLoading] = useState(false); // for fetching the insurance premium
  const [isTravelInsuranceAdded, setIsTravelInsuranceAdded] = useState(
    paymentCopyPage ? !!bookedInsuranceObj?.basePremium : false,
  );

  useEffect(() => {
    const getInsurancePremium = async (data) => {
      try {
        setLoading(true);
        console.log('Airlegit getInsurance request data: ', data);

        const response = await fetch(`${IBE_BASE_URL}/insurance/getPremium`, {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'content-type': 'application/json',
          },
        });

        if (!response?.ok) {
          throw new Error('Get Request failed for insurance premium API');
        }

        const responseJson = await response.json();

        console.log('Airlegit getInsurance Premium data: ', data);

        setQuote({ insurancePlanCost: responseJson?.insurancePremium });
        setIsTravelInsuranceAdded(true);
      } catch (error) {
        console.log('Error while getting Insurnce Premium ', error);
        setShowAdditionalService(false);
        setIsTravelInsuranceAdded(false);
        return;
      } finally {
        setLoading(false);
      }
    };

    if (showAdditionalService) {
      const insuranceReqData = airlegitInsurancePremiumReqFormater({
        fare: paymentConfirmation.billingDetail.amount,
        outboundPnrTrip,
        inboundPnrTrip,
      });

      getInsurancePremium(insuranceReqData);
    }
  }, []);

  return (
    <FormHandler onSubmit={onSubmit} onError={onValidationError}>
      <FormWrapper groupName="PaymentInfo">
        <PaymentInfoForm countries={countries} billingCard={billingCard} />
      </FormWrapper>

      {/* INSURANCE SELECTION */}
      {!manualImport && (
        <FormWrapper withOtherFormValues groupName="AdditionalService">
          <AdditionalServicesForm
            loading={loading}
            quote={quote}
            handleTravelInsurance={(value) => {
              setIsTravelInsuranceAdded(value);
            }}
            showAdditionalService={showAdditionalService}
          />
        </FormWrapper>
      )}

      {/* REFERRAL CODE */}
      <FormWrapper groupName="referralInfo">
        <ReferralCodeBlock />
      </FormWrapper>

      {/* REVIEW POLICY */}
      <ReviewPolicy
        links={{
          bookingRules: buildMarketingUrl(MARKETING_URL_BOOKINGRULES),
          termsConditions: buildMarketingUrl(MARKETING_TERMS_AND_CONDITIONS),
          privacyPolicy: buildMarketingUrl(MARKETING_PRIVACY_POLICY),
        }}
      />

      {/* PRICE SUMMARY */}
      <PriceSummary
        data={paymentConfirmation}
        enquiry={paymentConfirmation.enquiry}
        isTravelInsuranceAdded={isTravelInsuranceAdded}
        upgradeSupportPackage={!isUpgradeSupportReadOnly ? upgradeSupportPackage : null}
        quote={quote}
      />

      {paymentCopyPage ? (
        <FlexBox ma="v-big" justifyContent="center">
          <Text color="red" style={{ fontSize: '16px' }}>
            Authorized from {billingDetailCustomer.ipAddress}
          </Text>
        </FlexBox>
      ) : (
        <FlexBox ma="v-big" justifyContent="center">
          {confirmPaymentPending ? (
            <Loader size="small" inline />
          ) : (
            <Button type="submit" size="large" content="Authorize and Complete Booking" />
          )}
        </FlexBox>
      )}
    </FormHandler>
  );
};

export default compose(withCallTrackingUrl())(PaymentConfirmationForm);

const formatSegment = (segment) => {
  return {
    airlineName: segment.airline.name,
    airlineCode: segment.airline.code,
    flightno: segment.flightNumber,
    originAirportCode: segment.originAirport.code,
    destAirportCode: segment.destinationAirport.code,
    departuretime: moment.unix(segment.departureDate).utc().format('YYYY-MM-DD HH:mm:ss'),
  };
};

const airlegitInsurancePremiumReqFormater = (parameters) => {
  try {
    const { fare, outboundPnrTrip, inboundPnrTrip } = parameters;
    const isRoundTrip = !!inboundPnrTrip;

    const departureSegments = outboundPnrTrip.flightSegment.map((segment, index) => {
      if (segment.type !== 'SEGMENT') return null;

      return formatSegment(segment);
    });

    const arrivalSegments = isRoundTrip
      ? inboundPnrTrip.flightSegment.map((segment, index) => {
          if (segment.type !== 'SEGMENT') return null;

          return formatSegment(segment);
        })
      : [];

    return {
      tripCost: Number(fare).toFixed(2),
      tripStartDate: moment.unix(outboundPnrTrip.departureDate).utc().format('YYYY-MM-DD HH:mm:ss'),
      tripEndDate: isRoundTrip
        ? moment.unix(inboundPnrTrip.arrivalDate).utc().format('YYYY-MM-DD HH:mm:ss')
        : moment.unix(outboundPnrTrip.arrivalDate).utc().format('YYYY-MM-DD HH:mm:ss'),
      segments: [...departureSegments, ...arrivalSegments].filter(Boolean),
    };
  } catch (error) {
    console.error(
      'Error in PaymentConfirmationForm / airlegitInsurancePremiumReqFormater fn ',
      error?.message || JSON.stringify(error),
    );

    return null;
  }
};
