export const FOOTER_BANNER = 'footerBanner';

const initialState = {
  footerBannerData: null,
};

const footerBannerDataReducer = (state = initialState, action) => {
  if (action.type === FOOTER_BANNER) {
    return {
      ...state,
      footerBannerData: {
        content: action.value.content,
        callUsText: action.value.callUsText,
      },
    };
  }

  return state;
};

export const saveFooterBanner = (footerData) => {
  return {
    type: FOOTER_BANNER,
    value: footerData,
  };
};

export default footerBannerDataReducer;
