import './BookFlight.css';

import React from 'react';
import get from 'lodash/get';
import { compose } from 'redux';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { lifecycle, withProps, withStateHandlers } from 'recompose';

import {
  AccreditedBusinessBanner,
  CircularProgressbar,
  Desktop,
  FailedBooking,
  FlexBox,
  Header,
  Indent,
  Mobile,
  Overlay,
  Text,
} from 'travelopod-components';

import uuid from '../../helpers/uuid';
import pubnub from '../../api/pubnub/index';
import Loader from '../../components/Loader';
import withRouter from '../../Hocs/wihRouter';
import BookFlightForm from './BookFlightForm';
import withDomain from '../../Hocs/withDomain';
import Aside from '../../components/Aside/Aside';
import withRefetch from '../../Hocs/withRefetch';
import { formatPhone } from '../../helpers/phone';
import { markAsOpened } from '../../helpers/exitIntent';
import { parseExpirationDate } from '../../helpers/payments';
import { bookFlightSuccessUrl } from '../../constants/routes';
import Config, { IBE_BASE_URL } from '../../constants/config';
import { getTrafficSource } from '../../helpers/trafficSource';
import withCallTrackingUrl from '../../Hocs/withCallTrackingUrl';
import withScrollRestoration from '../../Hocs/withScrollRestoration';
import { updateBillingDetail } from '../../store/modules/payment/data';
import HelpBanner from '../../components/Banners/HelpBanner/HelpBanner';
import withVerifyFlightBooking from '../../Hocs/withVerifyFlightBooking';
import CountDataCollector from '../../components/Count/CountDataCollector';
import GuaranteedSafeAndSecureBanner from '../../components/Banners/GuaranteedSafeAndSecureBanner';
import { getPhoneDataSelector, setBookingFailedPhone, updatePhoneTFN } from '../../store/modules/responseTap/phoneData';

const serializeBookFlight = (props) => {
  const {
    additionalServices,
    contactDetails,
    passengers,
    paymentInfo,
    referralCode,
    subscriptionStatus,
    upgradeSupportPackage,
  } = props;
  const { month, year } = parseExpirationDate(paymentInfo.expirationDate);
  const quote = additionalServices?.quote;

  const data = {
    billing: {
      address: paymentInfo.address,
      city: paymentInfo.city,
      country: paymentInfo.country.name,
      countryCode: paymentInfo.country.code,
      phone: paymentInfo.phone,
      state: paymentInfo.state.code,
      zip: paymentInfo.zip,
    },
    contact: contactDetails,
    creditCard: {
      cardHolder: paymentInfo.cardName,
      cardType: paymentInfo.cardType,
      cvv: paymentInfo.cvv,
      expiryMonth: month,
      expiryYear: year,
      number: paymentInfo.cardNumber,
    },
    referralCode,
    passengers: passengers.map(({ birthday, ...passenger }) => ({
      ...pickBy(passenger),
      frequentFlyerNumber: {
        ...pickBy(passenger['frequentFlyerNumber']),
      },
      ...(birthday && {
        dob: {
          year: birthday.format('YYYY'),
          month: birthday.format('MM'),
          day: birthday.format('DD'),
        },
      }),
    })),
    trafficSource: getTrafficSource(),
    subscription: {
      sms: subscriptionStatus.smsSubscription,
      whatsapp: subscriptionStatus.whatsappSubscription,
    },
  };
  if (
    upgradeSupportPackage &&
    upgradeSupportPackage !== 'UPGRADE_BASIC' &&
    upgradeSupportPackage !== 'SUPPORT_STANDARD'
  ) {
    data.additionalService = { type: upgradeSupportPackage };
  }

  quote && (data.insuranceQuote = quote);

  return data;
};

const calculatePercentRatio = (percentage, ratio) => Math.round((percentage * ratio) / 100);

const getServerErrors = (response) => {
  const error = get(response, 'reason.cause.error');

  if (!error?.error) {
    return null;
  }

  const errorMessage = error.error;
  const lowerCaseErrorMessage = errorMessage.toLowerCase();

  if (lowerCaseErrorMessage.includes('zip code')) {
    return { zip: errorMessage };
  } else if (lowerCaseErrorMessage.includes('passenger last name is too long')) {
    const passengers = [];

    passengers[error.passengerIndex] = { lastName: errorMessage };

    return { passengers };
  }

  return null;
};

const FailedBookingComponent = ({ phone }) => (
  <FailedBooking phoneNumber={{ link: formatPhone(phone, 'RFC3966'), forRender: phone }} />
);

const FailedBookingWrapper = compose(
  lifecycle({
    componentDidMount() {
      this.props.setBookingFailedPhone();
      markAsOpened();
    },
  }),
)(FailedBookingComponent);

class BookFlightPage extends React.Component {
  constructor(props) {
    super(props);

    this.kountSessionId = uuid();
    this.bookFlightPercentageRatio = 80;
    this.bookingConfirmationPercentageRation = 20;
    this.state = {
      quote: 0,
      isBannersShown: false,
    };
  }

  componentDidMount() {
    pubnub.subscribe({
      channels: [`ch:${this.kountSessionId}`],
    });

    const listener = {
      message: (messageEvent) => {
        if (messageEvent?.channel === `ch:${this.kountSessionId}`) {
          const { percentage } = messageEvent?.payload || {};
          const currentPercentage = percentage > 100 ? 100 : percentage;
          this.props.changePercentage(calculatePercentRatio(currentPercentage, this.bookFlightPercentageRatio));
        }
      },
    };
    pubnub.addListener(listener);
  }

  componentWillUnmount() {
    const { bookFlightsResponse } = this.props;

    if (bookFlightsResponse?.value?.body) {
      pubnub.unsubscribe({
        channels: [`ch:${bookFlightsResponse.value.body.pnr}`],
      });
    }
  }

  handleBookingConfirmationChange = ({ message: { payload } }) => {
    const { percentage } = payload,
      difference = this.props.percentage - this.bookFlightPercentageRatio,
      nextPercentage = calculatePercentRatio(percentage, this.bookingConfirmationPercentageRation),
      isNextPercentageLargerPrevios = nextPercentage + this.props.percentage > this.props.percentage,
      mergedPercentage = this.props.percentage - difference + nextPercentage;

    this.props.changePercentage(isNextPercentageLargerPrevios ? mergedPercentage : this.props.percentage);
  };

  handleSubmit = (values) => {
    const { flightFetch, verifyBooking } = this.props;
    const [
      contactDetails,
      { passengers },
      paymentInfo,
      referralCode,
      additionalServices,
      subscriptionStatus,
      { upgradeSupportPackage, quote },
    ] = values;

    this.setState({
      quote: quote ? quote.insurancePlanCost : 0,
    });

    const data = serializeBookFlight({
      contactDetails,
      passengers,
      paymentInfo,
      additionalServices,
      upgradeSupportPackage,
      subscriptionStatus,
      ...referralCode,
    });

    const {
      flight: { summary, price, validatingAirlines, origin, destination, ...flight },
      searchParams,
    } = flightFetch.value;

    verifyBooking(flightFetch.value.flight, {
      ...data,
      flight,
      summary,
      price,
      validatingAirlines,
      originDestination: {
        origin: origin.airportCode,
        destination: destination.airportCode,
      },
      searchParams,
      sessionId: this.kountSessionId,
    });
  };

  componentDidUpdate(prevProps) {
    const { flightFetch, updateBillingDetail, bannerStatusFetch } = this.props;

    if (prevProps.flightFetch && prevProps.flightFetch.pending && flightFetch.fulfilled && flightFetch.value) {
      const { flight } = flightFetch.value;
      updateBillingDetail({
        amount: flight.price.total,
        currency: 'USD',
      });
    }

    if (
      prevProps.bookFlightsResponse &&
      prevProps.bookFlightsResponse.fulfilled !== this.props.bookFlightsResponse.fulfilled &&
      this.props.bookFlightsResponse.fulfilled
    ) {
      const {
        body: { pnr, bookedFlight },
      } = this.props.bookFlightsResponse.value;
      const { bookingConfirmation } = this.props;

      pubnub.unsubscribe({
        channels: [`ch:${this.kountSessionId}`],
      });

      pubnub.subscribe({
        channels: [`ch:${pnr}`],
      });

      const listener = {
        message: (messageEvent) => {
          if (messageEvent?.channel === `ch:${pnr}`) {
            this.handleBookingConfirmationChange(messageEvent);
          }

          console.log('messageEvent componentDidUpdate: ', messageEvent);
        },
      };

      pubnub.addListener(listener);

      bookingConfirmation({
        supplierCode: bookedFlight.supplierCode,
        pnr,
      });
    }

    if (
      prevProps.bookingConfirmationResponse &&
      prevProps.bookingConfirmationResponse.fulfilled !== this.props.bookingConfirmationResponse.fulfilled &&
      this.props.bookingConfirmationResponse.fulfilled
    ) {
      const { bookFlightsResponse, bookingConfirmationResponse, params } = this.props;
      const { quote } = this.state;
      const { compain } = params;
      const {
        body: { pnr, bookedFlight, passengerDetails, paymentDetail },
        emailAddress,
      } = bookFlightsResponse.value;

      setTimeout(() => {
        // Data For Google Reviews
        sessionStorage.setItem(
          'BookingDetails',
          JSON.stringify({
            orderId: pnr,
            date: new Date().toLocaleDateString('fr-CA'),
            orderValue: paymentDetail.total || 0,
            email: emailAddress,
            countryCode: passengerDetails.countryCode || 'US',
          }),
        );
        this.props.navigate(
          `${bookFlightSuccessUrl(
            compain ? { compain } : null,
            this.props.parseValuesToValues({
              pnr,
              supplierCode: bookedFlight.supplierCode,
            }),
          )}`,
          {
            state: {
              ...bookingConfirmationResponse.value,
              ...bookFlightsResponse.value.requestBody,
              quote,
            },
          },
        );
      }, 1000);
    }

    if (!bannerStatusFetch.pending && prevProps.bannerStatusFetch.pending !== bannerStatusFetch.pending) {
      this.setState({ isBannersShown: bannerStatusFetch.value?.data?.show });
    }
  }

  render() {
    const {
      billingCountriesFetch,
      flightFetch,
      bookFlightsResponse,
      percentage,
      showCircularLoader,
      phoneData: { phone },
      setBookingFailedPhone,
      isMKTSource,
      isSubmitButtonClicked,
      location,
    } = this.props;

    if (location?.state?.queryFilters) {
      sessionStorage.setItem('queryFilters', JSON.stringify(location.state.queryFilters));
    }

    const { isBannersShown } = this.state;
    const currencySource = isMKTSource();

    if (billingCountriesFetch.pending || flightFetch.pending) {
      return <Loader />;
    }

    const countries = billingCountriesFetch.value.data;
    const flight = flightFetch.value?.flight;
    const travelMarket = flightFetch.value?.travelMarket;

    const serverErrors = getServerErrors(bookFlightsResponse);

    if (!flight || (bookFlightsResponse?.reason && isEmpty(serverErrors))) {
      window.scrollTo(0, 0);
    }

    return (
      <div className="book-flight-page">
        {/* CIRCULAR LOADER IN CASE OF BOOKING */}
        {showCircularLoader && (
          <Overlay>
            <FlexBox direction="column" alignItems="center">
              <CircularProgressbar percentage={percentage} percentageTick={30} />
              <Header pa="t-big" as="h2" color="white">
                Completing your reservation…
              </Header>
              <Text pa="t l-large r-large" align="center" color="white">
                Please do not refresh this page or hit the back button while we process your request. This may take up
                to a minute.
              </Text>
            </FlexBox>
          </Overlay>
        )}

        <div className="book-flight-page-item">
          {!flight || (bookFlightsResponse?.reason && isEmpty(serverErrors)) ? (
            <>
              {/* BOOKING FAILURE WRAPPER */}
              <FailedBookingWrapper setBookingFailedPhone={setBookingFailedPhone} phone={phone} />
              <Mobile>
                <HelpBanner />
                <Indent ma="t-large">
                  <AccreditedBusinessBanner />
                </Indent>
              </Mobile>
            </>
          ) : (
            <>
              {/* BOOKING FORM */}
              <BookFlightForm
                isBannersShown={isBannersShown}
                flight={flight}
                countries={countries}
                onSubmit={this.handleSubmit}
                serverErrors={serverErrors}
                travelMarket={travelMarket}
                submitting={isSubmitButtonClicked || bookFlightsResponse?.pending}
                currencySource={currencySource}
              />

              <CountDataCollector sessionId={this.kountSessionId} />

              <Mobile>
                <HelpBanner />
                <Indent ma="t-large">
                  <AccreditedBusinessBanner />
                </Indent>
              </Mobile>
            </>
          )}
        </div>

        {/* ASIDE BLOCK - TIMER, TRUSTPILOT, ETC */}
        <Desktop>
          <Indent className="book-flight-page-aside">
            <Aside bookFlightPage={true} asideRoot={React.Fragment}>
              <GuaranteedSafeAndSecureBanner />
            </Aside>
          </Indent>
        </Desktop>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  phoneData: getPhoneDataSelector(state),
});

const mapDispatchToProps = {
  updateBillingDetail,
  updatePhoneTFN,
  setBookingFailedPhone,
};

export default compose(
  withScrollRestoration,
  withCallTrackingUrl(),
  withDomain(),
  withRefetch(({ params, getDomainName }) => {
    return {
      billingCountriesFetch: `${Config.baseURL}/api/billing-countries`,
      bannerStatusFetch: `${Config.callTrackingUrl}/api/call-tracking/banner`,
      flightFetch: `${IBE_BASE_URL}/flight?${queryString.stringify({ fk: params.flightKey.split('?')[0] })}`,
      bookFlight: (data) => {
        console.log('BookFlightPage > bookFlight() > data :', data);
        return {
          bookFlightsResponse: {
            url: `${IBE_BASE_URL}/book`,
            method: 'POST',
            body: JSON.stringify({
              ...data,
              domain: getDomainName(),
            }),
            force: true,
          },
        };
      },
      bookingConfirmation: ({ pnr, supplierCode }) => ({
        bookingConfirmationResponse: {
          url: `${IBE_BASE_URL}/loadConfirmation?pnr=${pnr}&supplierCode=${supplierCode}`,
          method: 'GET',
        },
      }),
    };
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withStateHandlers(
    {
      percentage: 0,
    },
    {
      changePercentage: () => (percentage) => ({
        percentage,
      }),
    },
  ),
  withProps((props) => ({
    showCircularLoader:
      get(props, 'bookFlightsResponse.pending', false) ||
      get(props, 'bookingConfirmationResponse.pending', false) ||
      (!!props.percentage &&
        !get(props, 'bookFlightsResponse.rejected', false) &&
        !!props.percentage &&
        !get(props, 'bookingConfirmationResponse.rejected', false)),
  })),
  withRouter,
  withVerifyFlightBooking({
    onBookFlight:
      ({ bookFlight, params }) =>
      (flight, flightBookingData) => {
        const { searchHash } = params;
        return bookFlight({
          ...flightBookingData,
          flight: {
            ...flightBookingData.flight,
            ...(searchHash && { searchHash }),
          },
          price: {
            ...flightBookingData.price,
            total: flight.price.total,
          },
        });
      },
    onContinueSearch:
      ({ params, navigate, location }) =>
      () => {
        const { compain } = params;

        const queryFilterFromSessionStorage = sessionStorage.getItem('queryFilters');

        window.scrollTo(0, 0);

        navigate({
          pathname: compain && `/${compain}` + '/search/',
          search: `?${queryString.stringify(location.state?.queryFilters || queryFilterFromSessionStorage, { arrayFormat: 'bracket' })}`,
          state: { invalidateCache: true },
        });
      },
  }),
)(BookFlightPage);
